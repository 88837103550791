<template>
  <div>
    <v-dialog v-model="dialog" scrollable persistent>
      <v-card style="border-radius:5px">

          <v-card-title primary-title class='primary white--text'>
          <span class="text-subtitle">Respostas</span>
          </v-card-title>

          <div class="px-2 pt-4" v-if="course_status == 'active' && !loading_dialog">
            <span class="text-h6 ml-6 mr-3">Aguardando início da fase de seleção.</span> 
            <v-divider class="mt-3"></v-divider>
          </div>

          <div class="px-2 pt-4" 
            v-if="!['selecting', 'active'].includes(course_status) && !loading_dialog && $route.name != 'Select'
          ">
            <span class="text-h6 ml-6 mr-3">Status da inscrição:</span> 
            <v-chip dark class="mb-1"
              :color="one_done_form.status == 'homologated' ?
              'success' : one_done_form.status == 'rejected' ?
              'red' : 'primary'"
            >
              <v-icon dense class="mr-2">
                {{
                  one_done_form.status == 'homologated' ?
                  'mdi-star' : one_done_form.status == 'rejected' ? 
                  'mdi-cancel' : one_done_form.status == 'fresh' ? 
                  'mdi-plus' : 'mdi-clock-outline'
                }}
              </v-icon>
              {{one_form_status_map[one_done_form.status]}}
            </v-chip>
            <v-divider class="mt-3"></v-divider>
          </div>
          
          <div class="px-2 pt-4" v-if="course_status == 'selecting' && !loading_dialog">
            <span class="text-h6 ml-6 mr-3">Status da inscrição</span> 
            <div class="mt-2 d-flex justify-space-around flex-wrap">
              
              <v-btn class="mt-3" rounded
                v-if="grade_one_roles"
                :color="one_done_form.status == 'selected' ? 'success' : 'blue-grey lighten-4'" 
                @click="setDoneFormStatus('selected')" 
                :disabled="disable_by_grade"
              ><v-icon left>mdi-check</v-icon> Selecionar</v-btn>

              <v-btn class="mt-3" rounded
                v-if="grade_one_roles"
                :color="one_done_form.status == 'selected_on_hold_1' ? 'primary' : 'blue-grey lighten-4'" 
                @click="setDoneFormStatus('selected_on_hold_1')" 
                :disabled="disable_by_grade"
              ><v-icon left>mdi-clock-outline</v-icon> Selecionar em espera 1</v-btn>

              <v-btn class="mt-3" rounded
                v-if="grade_one_roles"
                :color="one_done_form.status == 'selected_on_hold_2' ? 'primary' : 'blue-grey lighten-4'" 
                @click="setDoneFormStatus('selected_on_hold_2')" 
                :disabled="disable_by_grade"
              ><v-icon left>mdi-clock-outline</v-icon> Selecionar em espera 2</v-btn>

              <v-btn class="mt-3" rounded
                v-if="grade_one_roles"
                :color="one_done_form.status == 'selected_on_hold_3' ? 'primary' : 'blue-grey lighten-4'" 
                @click="setDoneFormStatus('selected_on_hold_3')" 
                :disabled="disable_by_grade"
              ><v-icon left>mdi-clock-outline</v-icon> Selecionar em espera 3</v-btn>

              <v-btn class="mt-3" rounded
                v-if="grade_two_roles"
                :color="one_done_form.status == 'rejected' ? 'error' : 'blue-grey lighten-4'" 
                @click="setDoneFormStatus('rejected')" 
              ><v-icon left>mdi-cancel</v-icon> Indeferir</v-btn>

              <v-btn class="mt-3" rounded
                v-if="grade_two_roles"
                :color="one_done_form.status == 'homologated' ? 'success' : 'blue-grey lighten-4'" 
                @click="setDoneFormStatus('homologated')"  
              ><v-icon left>mdi-star</v-icon> Homologar</v-btn>

            </div>
            <v-divider class="mt-3"></v-divider>
          </div>

          <card-loading-transparent v-if="loading_dialog"  class="pt-4"></card-loading-transparent>
          <v-card-text v-if="!loading_dialog">
            <v-list subheader class="pt-3">
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title><span class="text-h6">DADOS PESSOAIS</span></v-list-item-title>
                  <v-divider></v-divider>
                  <v-list-item-subtitle class="mt-3">Nome</v-list-item-subtitle>
                  <v-list-item-title class='mb-3'>{{one_done_form.name}}</v-list-item-title>
                  <v-list-item-subtitle class="mt-1">Idade</v-list-item-subtitle>
                  <v-list-item-title class='mb-3'>
                    {{ $dayjs(one_done_form.birthdate).format('LL')}} -
                    {{ $dayjs().diff($dayjs(one_done_form.birthdate).format('YYYY-MM-DD'), 'year' )}} anos.
                  </v-list-item-title>
                  <v-list-item-subtitle class="mt-1">Nacionalidade</v-list-item-subtitle>
                  <!-- <v-list-item-subtitle>{{countries_map[one_done_form.country]}}</v-list-item-subtitle> -->
                  <v-list-item-title class='mb-3'>{{countries_map[one_done_form.country] || one_done_form.country}}</v-list-item-title>
                  <v-list-item-subtitle class="mt-1">Estado</v-list-item-subtitle>
                  <v-list-item-title class='mb-3'>{{one_done_form.state}}</v-list-item-title>
                  <v-list-item-subtitle class="mt-1">Cidade</v-list-item-subtitle>
                  <v-list-item-title class='mb-3'>{{one_done_form.city}}</v-list-item-title>
                  <v-list-item-subtitle class="mt-1">E-mail</v-list-item-subtitle>
                  <v-list-item-title class='mb-3'>{{one_done_form.email}}</v-list-item-title>
                  <v-list-item-subtitle class="mt-1">Telefone</v-list-item-subtitle>
                  <v-list-item-title class='mb-3'>{{one_done_form.phone}}</v-list-item-title>
                  <v-list-item-subtitle v-if="one_done_form.document" class="mt-1">Documento</v-list-item-subtitle>
                  <v-list-item-title class='mb-3' v-if="one_done_form.document">{{one_done_form.document}}</v-list-item-title>
                  <v-list-item-subtitle v-if="one_done_form.curriculum_link" class="mt-1">Curriculum</v-list-item-subtitle>
                  <v-list-item-title class='mb-3' v-if="one_done_form.curriculum_link">
                    <a :href="one_done_form.curriculum_link" target="_blank">
                      <v-chip class="px-5 mt-2" link color="primary">
                        <v-icon left small>mdi-open-in-new</v-icon>
                        <span>ABRIR LINK</span>
                      </v-chip>
                    </a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list subheader v-for="item in form_items" :key="item.id" class="py-0">
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <div :expansion="4" class="mb-5">
                    <v-list-item-subtitle class="text-wrap" v-if="item.answer">{{item.title}}</v-list-item-subtitle>
                    <v-list-item-title class="text-wrap" v-if="!item.answer"><span class="text-h6">{{item.title}}</span></v-list-item-title>
                    <v-list-item-title v-if="item.answer != 'null'" class="text-wrap">{{item.answer}}</v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      <v-btn v-if="item.file_url" :disabled="item.file_url == null" class="" small color="primary" dark @click="dialog_file = true"><v-icon left>mdi-file</v-icon>Abrir carta</v-btn>
                    </v-list-item-subtitle>
                  </div>
                  <dialog-show-file v-if="item.file_url" title="Carta da Chefia" :file_url="item.file_url" :file_kind="item.file_kind" :dialog_file="dialog_file" @closeDialog="dialog_file = false"></dialog-show-file>
                  
                  <v-divider v-if="!item.answer" class="mt-n8"></v-divider>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="error" @click="closeDialog"><v-icon left>mdi-close</v-icon>Fechar</v-btn>
          </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'DialogEnrollmentDoneForm',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    selected_done_form: {
      type: Object,
      default: ()=> {},
    },
    course_status:{
      type: String,
      default: 'active',
    }
  },
  data() {
    return {
      dialog_file: false,
      one_form_status_map:{
        fresh: 'Novo', 
        selected: 'Selecionado', 
        selected_on_hold: 'Selecionado em espera', 
        selected_on_hold_1: 'Selecionado em espera 1', 
        selected_on_hold_2: 'Selecionado em espera 2', 
        selected_on_hold_3: 'Selecionado em espera 3', 
        rejected: 'Indeferido', 
        homologated: 'Homologado'
      },
      countries_map:{ 
        brazilian: 'Brasileiro', 
        argentine: 'Argentina', 
        colombian: 'Colombia', 
        uruguayan: 'Uruguai', 
        paraguayan: 'Paraguai', 
        other: 'Outro', 
      },

      loading_dialog: false,
      form_items: [],
      one_done_form: {}
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    async loadDialog(){
      this.loading_dialog = true
      this.one_done_form = this.selected_done_form
      if(!this.one_done_form.curriculum_link){
        let done_personal_infos = await this.getPersonalInfoCurriculumLink()
        this.one_done_form.curriculum_link = done_personal_infos.curriculum_file_url
      }
      //this.one_done_form = await this.getDoneForm()
      if(this.one_done_form.user_id){
        this.form_items = await this.getDoneItems(this.one_done_form.id)
      }else if(this.one_done_form.done_form_id){
        this.form_items = await this.getDoneItems(this.one_done_form.done_form_id)
      }
      this.loading_dialog = false
    },
    async getDoneItems(id){
      return await Api.DoneItems.indexByDoneForm(id).then(r=>r.data).then(d=>{
        return d
      })
    },
    async getPersonalInfoCurriculumLink(){
      return await Api.DonePersonalInfos.show(this.selected_done_form.done_personal_info_id).then(r=>r.data).then(d=> {
        return d
      })
    },
    // async getDoneForm(){
    //   return await Api.DoneForm.show(this.selected_done_form.id).then(r=>r.data).then(d=>{
    //     return d
    //   })
    // },
    prepareDoneForm(value){
      let done_form = {
        id: this.selected_done_form.id,
        status: value
      }
      return done_form
    },
    setDoneFormStatus(value){
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        title: `Deseja realizar esta alteração ?`,
        icon: 'question',
      }).then(r=>{
        if(r.isConfirmed){
          let done_form = this.prepareDoneForm(value)
          this.updateDoneForm(done_form)
        };
      });
    },
    updateDoneForm(done_form){
      Api.DoneForm.update(done_form).then(() => {
        this.$emit('setDoneFormStatus', {value: done_form.status, id: done_form.id})
        this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
      }).catch((err) =>{
        let msg = err.response.data.errors[0]
        this.addNotification({type: 'error', msg: `Erro: ${msg}`})
      })
    },
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
  computed: {
    disable_by_grade(){
      return (this.user_role == 'coordinator' && this.one_done_form.status == 'rejected') || 
      (this.user_role == 'coordinator' &&  this.one_done_form.status == 'homologated')
    },
    grade_one_roles(){
      return ['director', 'admin', 'coordinator', 'super_admin'].includes(this.user_role)
    },
    grade_two_roles(){
      return ['director', 'admin', 'super_admin'].includes(this.user_role)
    },
    ...mapState({
      user_role: state=> state.User.user.role.name
    }),
  },

  mounted () {
    this.loadDialog();
  },
}
</script>

<style scoped>

</style>